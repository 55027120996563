@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pixelated Elegance Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Pixelated Elegance Regular'), url('./style/PixelatedEleganceRegular-drlg6.woff') format('woff');
}

@font-face {
	font-family: 'Arial';
	src: local('Arial'), url('./style/Arial.woff') format('woff');
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-logo {
  animation: rotate 2s linear infinite;
}
