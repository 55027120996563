$light-purple: #e9ebf8;
$theme-color: #2C3A9B;

// Login page

html {
    scroll-behavior: smooth;
}

.login_back {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/png/login-back.png');
    background-size: cover;
    background-position: center;
}

.login_back_gradient {
    // background: linear-gradient(110.45deg, #9CA8E7 -26.78%, #11234B 1.67%, #616ECB 110.42%, #4285F4 116.37%);
    background: linear-gradient(180deg, #FFFFFF 0%, #2C3A9B 196.18%);
}

.texts-gradient {
    background: linear-gradient(270deg, #2b47fd, #a067fc 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.font-new {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.blog_banner {
    background-image: url('../images/zunno-blog/back_img.png');
    background-size: cover;
    padding: 50px 0;
}

// .space.gx-lg-5 {
//     --bs-gutter-x: 6rem !important;
// }
// .space.gy-4 {
//     --bs-gutter-y: 4rem !important;
// }

.scrolling::-webkit-scrollbar {
    height: 0 !important;
}

// Home page

.side_menu {
    a.active {
        background-color: $light-purple;
    }
}

.dropdown {
    button::after {
        display: none;
    }
}

.avtar_tab {
    .nav-link {
        padding: 6px 30px;
        font-size: 12px;
        background-color: white;
        border: 1px solid #CBD0DC;
        color: black;
        background-color: transparent !important;

        &.active {
            background: linear-gradient(101.63deg, #2C3A9B -52.25%, #B725E2 44.79%, #FD34F8 151.34%);
        }

        &:hover {
            color: white;
            background: linear-gradient(101.63deg, #2C3A9B -52.25%, #B725E2 44.79%, #FD34F8 151.34%);
        }
    }
}

.create_blank {
    &:hover {
        background: linear-gradient(144.36deg, #2C3A9B -8.43%, #B725E2 104.04%);
        border-style: solid;
    }
}

.final_avatar {
    // background: radial-gradient(79.99% 258.02% at 44.32% 76.81%, rgba(44, 58, 155, 0.1) 0%, rgba(183, 37, 226, 0.2) 48%, rgba(77, 0, 133, 0.1) 100%);
    // padding: 30px;
    border-radius: 6px;
    overflow: hidden;
    background-color: black;

    .loading-bar {
        display: flex;
        height: 30px;
        padding: 6px;
        width: 100%;
        max-width: 320px;
        box-shadow: inset 0px 0px 0px 1px #C8C8CD;
        border-radius: 50px;
        overflow: hidden;
        margin: auto;

        @keyframes progress-animation {
            0% {
                width: 0%;
            }

            20% {
                width: 10%;
            }

            40% {
                width: 30%;
            }

            50% {
                width: 60%;
            }

            100% {
                width: 90%;
            }
        }

        .progress-bar {
            display: flex;
            height: 100%;
            width: 90%;
            background: $theme-color;
            border-radius: 50px;
            animation: progress-animation 5s ease-in-out;
        }
    }
}

.generate-modal {
    .modal-content {
        // background: linear-gradient(123.72deg, #F3F8FC -8.76%, #FBF2F7 49.12%, #E9EAFE 109.1%);
    }

    .modal-dialog {
        max-width: 550px !important;
        transition: .25s ease-in-out !important;
    }

    &.modal.fade .modal-dialog {
        transform: scale(0.8) !important;
        transition: .25s ease-in-out !important;
    }

    &.modal.show .modal-dialog {
        transform: scale(1) !important;
        transition: .25s ease-in-out !important;
    }

    .modal-scroll::-webkit-scrollbar {
        width: 10px !important;
    }
}

.modal.show {
    backdrop-filter: blur(4px);
}


.final_avatar_back {
    background: radial-gradient(79.99% 258.02% at 44.32% 76.81%, rgba(44, 58, 155, 0.1) 0%, rgba(183, 37, 226, 0.2) 48%, rgba(77, 0, 133, 0.1) 100%);
}

.payment_tooltip span {
    opacity: 0;
    visibility: hidden;
}

.payment_tooltip:hover span {
    opacity: 1;
    visibility: visible;
}

.create-side-animation {
    animation: slide 200ms linear;
}

@keyframes slide {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }

    50% {
        transform: translateY(5px);
        opacity: 0.5;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.form-check.form-switch {
    label {
        font-size: 14px !important;
    }

    .form-check-input {
        &:checked {
            // background-color: #000 !important;
            background: linear-gradient(270deg, #2b47fd, #a067fc 90%);
            box-shadow: none;
        }

        &:focus {
            box-shadow: none;
        }
    }
}

// .switch:checked+.switch_tab {
//     background: linear-gradient(270deg, #2b47fd, #a067fc 90%);
// }

.sub_menu {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.main_menu:hover .sub_menu {
    opacity: 1;
    visibility: visible;
}

.sub_menu a:hover .icon,
.icon_animation:hover .icon {
    // margin-bottom: 4px;
    transform: translateY(-8%);
}

.modal.fade .modal-dialog {
    transform: scale(0.8) !important;
}

.modal.show .modal-dialog {
    transform: scale(1) !important;
}

.service_tab.nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    gap: 40px;
}

.service_tab.nav-tabs .nav-link.active {
    background-color: transparent;
    border: 0;
    border-bottom: 3px solid rgba(86, 97, 175, 0.2);
    color: var(--theme-color);
}

.service_tab.nav-tabs .nav-link {
    border: 0;
    color: #000;
    padding: 10px 0;
    font-size: 18px;
}

.service_tab.nav-tabs .nav-link:hover {
    color: var(--theme-color);
}

.service_tab.nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.service_tab.nav-tabs::-webkit-scrollbar {
    height: 0;
}

.react-datepicker-wrapper {
    display: block !important;
}

.modal-xxl.modal-dialog {
    max-width: 1500px !important;
    margin: 0.75rem auto !important;
}

.modal-dialog-scrollable {
    height: calc(100% - 0.75rem * 2) !important;
}

.back_gradient {
    background: linear-gradient(161.17deg, #9F68FD -14.01%, #6457FE 48.53%, #344AFF 112.98%);
}

.layer {
    transition: left 0.5s ease, transform 0.5s ease;
}

.layer.bounce {
    animation: bounce 0.5s ease-out;
}

.btn-close {
    box-shadow: none !important;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }

    40% {
        transform: translateX(-20px);
    }

    60% {
        transform: translateX(5px);
    }
}

.lines::after {
    content: '';
    width: 3px;
    height: 90%;
    border-radius: 10px;
    position: absolute;
    left: -8px;
    background: linear-gradient(91.39deg, #2C3A9B 1.19%, #8894E7 132.82%);
}

.subs_modal {
    &.offcanvas.offcanvas-bottom {
        height: 100vh !important;
    }

    &.offcanvas-backdrop.show {
        opacity: 0 !important;
    }

    &.offcanvas {
        background: linear-gradient(110.45deg, #9CA8E7 -26.78%, #11234B 1.67%, #616ECB 110.42%, #4285F4 116.37%);
    }
}

.close_btn_ .btn-close {
    filter: invert(100%);
    box-shadow: none;
}

.gradient_border {
    // border: 1px solid;
    height: 100%;
    width: 2px;
    background: linear-gradient(132.21deg, #2C3A9B -8.84%, #3EEFDA 109.97%);
    margin: auto;
}

.subs_faq {
    .accordion-button:not(.collapsed) {
        background-color: transparent !important;
        box-shadow: none !important;
    }

    .accordion-button:focus {
        box-shadow: none !important;
    }

    .accordion-item {
        // border-radius: 8px;
        overflow: hidden;
        // margin-top: 20px;
    }
}

.pre_options {
    .nav-pills {
        margin: 0 12px !important;
    }

    .nav-link {
        padding: 10px 24px !important;
        color: #000 !important;
        margin-bottom: 4px;

        &.active {
            background-color: $light-purple !important;
        }

        &:hover {
            background-color: $light-purple !important;
        }
    }

    .dropdown-item:active {
        background-color: #f8f9fa !important;
        color: #000 !important;
    }

    .scrolls {
        &::-webkit-scrollbar {
            width: 5px !important;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 8px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 8px;
        }
    }
}

.back_check {
    &:checked+div {
        background: linear-gradient(95.21deg, hwb(232 17% 39%) 1.9%, #6F7FEB 83.31%);
    }
}

.back_check1 {
    &:checked+div {
        // background: linear-gradient(95.21deg, rgba(44, 58, 155, 0.2) 1.9%, rgba(111, 127, 235, 0.2) 83.31%);
        background-color: $light-purple !important;
    }
}

@media screen and (max-width: 991px) {
    .login_back {
        padding: 10px 0;
    }

    .logo_img img {
        margin: auto;
    }
}

#simple-list-example .active {
    color: #4f46e5;
    font-weight: bold;
}

.line::after {
    content: '';
    width: 4px;
    height: 100%;
    background: linear-gradient(270deg, #2b47fd, #a067fc 90%);
    position: absolute;
    left: -10px;
    top: 0;
    border-radius: 5px;
    transition: .4s;
}

.blog_menu {
    a {
        position: relative;

        &::after {
            content: '';
            width: 0;
            height: 2px;
            background: linear-gradient(270deg, #2b47fd, #a067fc 90%);
            position: absolute;
            left: 0;
            bottom: 0;
            transition: .4s;
        }

        &:hover::after {
            width: 100%;
        }
    }
}

.shadow1 {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.arrow_hover {
    &:hover {
        .arow_icon {
            left: 0px;
            opacity: 1;
        }
    }
}

.line_effect {
    display: inline;
    background: linear-gradient(to left, #131313, #131313);
    -webkit-background-size: 0 1px;
    background-size: 0 1px;
    -webkit-background-position: 0 100%, 100% 100%;
    background-position: 0 100%, 100% 100%;
    background-repeat: no-repeat;
    -webkit-transition: background-size cubic-bezier(0.8, 0, 0.2, 1) 400ms;
    transition: background-size cubic-bezier(0.8, 0, 0.2, 1) 400ms;
    // font-size: 2.5rem;
    font-weight: normal;
    font-variation-settings: "wght" 500, "wdth" 75;
    line-height: 1.15;
    letter-spacing: -0.001em;
}

.line_effect:hover,
.line_effect:focus {
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-background-size: 100% 1px;
    background-size: 100% 1px;
}

.dislike_modal .modal-dialog {
    max-width: 600px !important;
}